import React from 'react';
import botiga from '../../images/botiga.png';
import { LuExternalLink } from "react-icons/lu";

const Botiga = () => {

    return (
        <React.Fragment>
            <div className='bg-botiga sm:p-20 p-10 text-textBotiga font-bold w-full sm:w-1/2 flex text-center items-center justify-center flex-col'>
                <a href='https://stampa-shop.com/' target="_blank" rel="noreferrer">
                    <div className='hover:bg-taller rounded-md p-5 transition duration-500'>
                        <h1 className='uppercase text-5xl '>La botiga</h1>
                        <h2 className='text-xl'>La nostra botiga per al teu taller DIY</h2>
                        <div className='flex flex-row items-center text-center justify-center text-xl'>
                            <h3 className='mr-1'>stampa-shop.com</h3>
                            <LuExternalLink className=''/>
                        </div>
                    </div>
                </a>
                <img src={botiga} alt='Imatge Taller' className=' object-contain h-96 w-96 hidden sm:block'/>
            </div>
        </React.Fragment>
    );
}

export default Botiga;