import React from 'react';
import Footer from "./components/footer";
import Header from "./components/header";
import Main from "./views/main";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </div>
  );
}

export default App;
