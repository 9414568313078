import React from 'react';
import logo from '../images/logo.png';

const Header = () => {

    return (
        <React.Fragment>
            <header>
                <div className='bg-black w-full h-auto flex flex-col items-start sm:items-center sm:flex-row px-5 py-3'>
                    <img src={logo} alt='logo'/>
                    <p className='text-white pl-0 sm:pl-20 font-sans font-bold sm:text-xl text-base mt-3 sm:mt-0'>Som Stampa, un taller de serigrafia artesanal, ubicat des del 2014 a Esplugues del Llobregat molt a prop de Barcelona. En aquesta pàgina hi trobareu tota la informació sobre els serveis que oferim.</p>
                </div>
            </header>
        </React.Fragment>
    );
}

export default Header;