import React from 'react';
import Taller from './components/taller';
import Botiga from './components/botiga';

const Main = () => {

    return (
        <div className='w-full flex sm:flex-row flex-col flex-grow'>
            <Taller />
            <Botiga/>
        </div>
    );
}

export default Main;