import React from 'react';
import taller from '../../images/taller.png';
import { LuExternalLink } from "react-icons/lu";

const Taller = () => {

    return (
        <React.Fragment>
            <div className='bg-taller sm:p-20 p-10 text-textTaller font-bold w-full sm:w-1/2 flex text-center items-center justify-center flex-col'>
            <a href='https://stampa-serigrafia.com/' target="_blank" rel="noreferrer">
                <div className='hover:bg-botiga rounded-md p-5 transition duration-500'>
                    <h1 className='uppercase text-5xl '>El taller</h1>
                    <h2 className='text-xl'>El nostre taller de personalització</h2>
                    <div className='flex flex-row items-center text-center justify-center text-xl'>
                        <h3 className='mr-1'>stampa-serigrafia.com </h3>
                        <LuExternalLink className=''/>
                    </div>
                </div>
            </a>
                <img src={taller} alt='Imatge Taller' className='object-contain h-96 w-96 hidden sm:block'/>
            </div>
        </React.Fragment>
    );
}

export default Taller;