import React from 'react';
import { LuMail, LuFacebook, LuInstagram } from "react-icons/lu";

const Footer = () => {

    return (
        <React.Fragment>
            <footer>
                <div className='px-5 py-4 bg-black w-full h-auto flex flex-col sm:flex-row md:flex-row items-start justify-between'>
                    <div className='text-white flex flex-col sm:flex-row w-full justify-between'>
                        <div className='flex flex-row text-3xl space-x-3 mb-3 sm:mb-0'>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/stampa.cat/"><LuFacebook /></a>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/stampa_serigrafia/?hl=es"><LuInstagram/></a>
                            <a target="_blank" rel="noreferrer" href="mailto:info@stampa.cat"><LuMail /></a>
                        </div>
                        <p className='font-sans font-bold sm:text-xl text-base'>Copyright 2024 © Stampa Serigrafia SL</p>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;